import React from "react";

import Mold from '../../Images/Mold.webp'
import { useTranslation } from "react-i18next";
import SLogo from '../../Images/Home/NewImages/Slogo.webp'

const S4 = () => {

    const {t} = useTranslation()
    
    return(
        <React.Fragment>
            
            <div className="Services">
                
                <h1>{t('S4')}</h1>
                
                <div className="Content">
    
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S4C1')}</p>
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S4C2')}</p>
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S4C3')}</p>

                    <img src={Mold} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    
                </div>

            </div>

        </React.Fragment>
    )

}

export default S4