import React from "react";
import { useTranslation } from "react-i18next";
import S3i from '../../Images/S3.webp'
import SLogo from '../../Images/Home/NewImages/Slogo.webp'

const S3 = () => {

    const {t} = useTranslation()
    
    return(
        <React.Fragment>
            
            <div className="Services">
            
            <h1>{t('S3')}</h1>
                
                <div className="Content">
    
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S3C1')}</p>
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S3C2')} ,{t('S3C3')} ,{t('S3C4')}</p>
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S3C5')}</p>

                    <img src={S3i} style={{width: '100%', height: 'auto'}} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    
                </div>

            </div>

        </React.Fragment>
    )
}

export default S3