import React from "react";
import { useTranslation } from "react-i18next";
import H9 from '../../Images/Home/NewImages/I9.webp'
import SLogo from '../../Images/Home/NewImages/Slogo.webp'

const S2 = () => {
    
    const {t} = useTranslation()

    return(
        <React.Fragment>
            
            <div className="Services">
            
                <h1>{t('S2')}</h1>
                
                <div className="Content">
    
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S2C1')}</p>
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S2C2')}</p>

                    <img src={H9} style={{width: '80%', height: 'auto'}} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    
                </div>

            </div>

        </React.Fragment>
    )

}

export default S2