import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Catalog from "./Components/Catalog";

function App(){
  return(
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/Catalog" element={<Catalog/>}/>
    </Routes>
  )
}

export default App