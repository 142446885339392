import React from "react";
import '../../Styles/S.css'
import { useTranslation } from "react-i18next";
import S1i from '../../Images/S1.webp'
import SLogo from '../../Images/Home/NewImages/Slogo.webp'

const S1 = () => {

    const {t} = useTranslation()
    
    return(
        <React.Fragment>
            
            <div className="Services">
            
                <h1>{t('S1')}</h1>
                
                <div className="Content">
    
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S1C1')}</p>
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S1C2')}</p>
                    <p style={{textAlign: t('alignment'), flexDirection: t('flex')}}> <img src={SLogo} style={{width: '30px', height: '30px'}}/> {t('S1C3')}</p>

                    <img src={S1i} style={{width: '100%', height: 'auto'}} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    
                </div>

            </div>

        </React.Fragment>
    )

}

export default S1