import React, { useEffect, useRef } from "react";
import '../Styles/Catalog/Csec-1.css'
import { FaFilePdf } from "react-icons/fa";
//import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";

import Logo from '../Images/Logo.jpg'

import I1 from '../Images/Catalog/Home/web/I1.webp'
import I2 from '../Images/Catalog/Home/web/I2.webp'
import I3 from '../Images/Catalog/Home/web/I3.webp'
import I4 from '../Images/Catalog/Home/web/I4.webp'
import I5 from '../Images/Catalog/Home/web/I5.webp'
import I6 from '../Images/Catalog/Home/web/I6.webp'
import I7 from '../Images/Catalog/Home/web/I7.webp'
import I8 from '../Images/Catalog/Home/web/I8.webp'
import I9 from '../Images/Catalog/Home/web/I9.webp'
import I10 from '../Images/Catalog/Home/web/I10.webp'
import I11 from '../Images/Catalog/Home/web/I11.webp'
import I12 from '../Images/Catalog/Home/web/I12.webp'
import I13 from '../Images/Catalog/Home/web/I13.webp'
import I14 from '../Images/Catalog/Home/web/I14.webp'
import I15 from '../Images/Catalog/Home/web/I15.webp'
import I16 from '../Images/Catalog/Home/web/I16.webp'
import I17 from '../Images/Catalog/Home/web/I17.webp'
import I18 from '../Images/Catalog/Home/web/I18.webp'
import I19 from '../Images/Catalog/Home/web/I19.webp'
import I20 from '../Images/Catalog/Home/web/I20.webp'
import I21 from '../Images/Catalog/Home/web/I21.webp'
import I22 from '../Images/Catalog/Home/web/I22.webp'
import I23 from '../Images/Catalog/Home/web/I23.webp'
import I24 from '../Images/Catalog/Home/web/I24.webp'
import I25 from '../Images/Catalog/Home/web/I25.webp'
import I26 from '../Images/Catalog/Home/web/I26.webp'
import I27 from '../Images/Catalog/Home/web/I27.webp'
import I28 from '../Images/Catalog/Home/web/I28.webp'
import I29 from '../Images/Catalog/Home/web/I29.webp'
import I30 from '../Images/Catalog/Home/web/I30.webp'
import I31 from '../Images/Catalog/Home/web/I31.webp'
import I32 from '../Images/Catalog/Home/web/I32.webp'
import I33 from '../Images/Catalog/Home/web/I33.webp'
import I34 from '../Images/Catalog/Home/web/I34.webp'
import I35 from '../Images/Catalog/Home/web/I35.webp'
import I36 from '../Images/Catalog/Home/web/I36.webp'
import I37 from '../Images/Catalog/Home/web/I37.webp'
import I38 from '../Images/Catalog/Home/web/I38.webp'
import I39 from '../Images/Catalog/Home/web/I39.webp'
import I40 from '../Images/Catalog/Home/web/I40.webp'
import I41 from '../Images/Catalog/Home/web/I41.webp'

//catalog drawing

import D1 from '../Images/Catalog/drawing/1.webp'
import D2 from '../Images/Catalog/drawing/2.webp'
import D3 from '../Images/Catalog/drawing/3.webp'
import D4 from '../Images/Catalog/drawing/4.webp'
import D5 from '../Images/Catalog/drawing/5.webp'
import D6 from '../Images/Catalog/drawing/6.webp'
import D7 from '../Images/Catalog/drawing/7.webp'
import D9 from '../Images/Catalog/drawing/9.webp'
import D10 from '../Images/Catalog/drawing/10.webp'
import D11 from '../Images/Catalog/drawing/11.webp'
import D13 from '../Images/Catalog/drawing/13.webp'
import D12 from '../Images/Catalog/drawing/12.webp'
import D14 from '../Images/Catalog/drawing/14.webp'
import D15 from '../Images/Catalog/drawing/15.webp'
import D16 from '../Images/Catalog/drawing/16.webp'
import D17 from '../Images/Catalog/drawing/17.webp'
import D18 from '../Images/Catalog/drawing/18.webp'
import D19 from '../Images/Catalog/drawing/19.webp'
import D20 from '../Images/Catalog/drawing/20.webp'
import D21 from '../Images/Catalog/drawing/21.webp'
import D22 from '../Images/Catalog/drawing/22.webp'
import D23 from '../Images/Catalog/drawing/23.webp'
import D24 from '../Images/Catalog/drawing/24.webp'
import D25 from '../Images/Catalog/drawing/25.webp'
import D26 from '../Images/Catalog/drawing/26.webp'
import D27 from '../Images/Catalog/drawing/27.webp'
import D28 from '../Images/Catalog/drawing/28.webp'
import D29 from '../Images/Catalog/drawing/29.webp'
import D30 from '../Images/Catalog/drawing/30.webp'
import D31 from '../Images/Catalog/drawing/31.webp'
import D32 from '../Images/Catalog/drawing/32.webp'
import D33 from '../Images/Catalog/drawing/33.webp'
import D34 from '../Images/Catalog/drawing/34.webp'
import D35 from '../Images/Catalog/drawing/35.webp'
import D36 from '../Images/Catalog/drawing/36.webp'

const Catalog = () => {

    const { pathname } = useLocation();
    const catalogRef = useRef(null);
    const {t} = useTranslation()

    useEffect(() => {
       window.scrollTo(0, 0)
      }, [pathname]);

      const handleDownloadPDF = () => {
        html2canvas(catalogRef.current).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4'); 
            const imgWidth = 210; 
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            let position = 10;
            let remainingHeight = imgHeight;

            const totalHeight = catalogRef.current.offsetHeight;

            while (remainingHeight < totalHeight) {
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                remainingHeight += imgHeight;
                position -= 297; 
                if (remainingHeight < totalHeight) {
                    pdf.addPage(); 
                }
            }
            pdf.save("catalog.pdf");
        });
    };
    

    return(
        <React.Fragment>

            <div className="Csec-1">

                <h1>{t('Catalog')}</h1>
                <svg xmlns="http://www.w3.org/2000/svg" width="304" height="5" viewBox="0 0 304 5" fill="none">
                  <path d="M1.99921 2.48071H301.999" stroke="#00B9F2" stroke-width="4" stroke-linecap="round"/>
                </svg>

                <a href="https://firebasestorage.googleapis.com/v0/b/plastic-rayan-915c7.appspot.com/o/catalog.pdf?alt=media&token=da27addd-a4a4-4f1c-9a79-b392bcfc2eb4" target="_blank" rel="noreferrer"><FaFilePdf/> PDF</a>

            </div>

            <div className="Csec-2" ref={catalogRef}>

                <div className="Product">

                    <img src={I40}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D1}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040010</p>
                        <p>Capacity: 2L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 92g</p>
                        <p className="Color">Color: transparent</p>

                    </div>

                </div>

                <div className="Product">

                    <img src={I1}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D2}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040020</p>
                        <p>Capacity: 2L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 92g</p>
                        <p className="Color">Color: transparent</p>

                    </div>

                </div>

                 <div className="Product">

                    <img src={I17} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D3}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040040</p>
                        <p>Capacity: 1.9L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 92g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I18} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D4}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040030</p>
                        <p>Capacity: 2L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 92g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I2}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D5}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040050</p>
                        <p>Capacity: 1.8L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 55g, 92g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I13} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D6}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040060</p>
                        <p>Capacity: 1L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 55g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I14} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D7}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040070</p>
                        <p>Capacity: 1L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 95mm</p>
                        <p>Weight: 55g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I15} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D9}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040080</p>
                        <p>Capacity: 760ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 55g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>
                
                <div className="Product">

                    <img src={I16} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D10}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040090</p>
                        <p>Capacity: 760ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 95mm</p>
                        <p>Weight: 55g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>


                <div className="Product">

                    <img src={I19} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D11}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040011</p>
                        <p>Capacity: 500ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 55g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I20} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D12}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040012</p>
                        <p>Capacity: 500ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 95mm</p>
                        <p>Weight: 55g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I21} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D13}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 040013</p>
                        <p>Capacity: 250ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 62mm</p>
                        <p>Weight: 25g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I5}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D14}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030010</p>
                        <p>Capacity: 1.5L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 46g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I4}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D15}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030020</p>
                        <p>Capacity: 1.5L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 28mm</p>
                        <p>Weight: 60g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I6}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D16}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030030</p>
                        <p>Capacity: 1L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 46g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I7}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D17}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030040</p>
                        <p>Capacity: 1L </p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 46g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I8}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D18}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030050</p>
                        <p>Capacity: 1L</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 46g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I9}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D19}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030060</p>
                        <p>Capacity: 850ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 28mm</p>
                        <p>Weight: 30g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I3}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>

                    <img src={D20}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030070</p>
                        <p>Capacity: 500ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 32g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I10} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D21}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030080</p>
                        <p>Capacity: 330ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 32g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I11} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D22}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 030090</p>
                        <p>Capacity: 250ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 32g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I12} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D23}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 060010</p>
                        <p>Capacity: 120ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 22mm</p>
                        <p>Weight: 13g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>


                <div className="Product">

                    <img src={I22} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D24}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 050010</p>
                        <p>Capacity: 200ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 52mm</p>
                        <p>Weight: 25g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I23} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D25}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 050020</p>
                        <p>Capacity: 210ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 52mm</p>
                        <p>Weight: 25g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I24} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D26}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 050030</p>
                        <p>Capacity: 220ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 52mm</p>
                        <p>Weight: 25g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I41} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D36}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 050040</p>
                        <p>Capacity: 220ML</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 52mm</p>
                        <p>Weight: 25g</p>
                        <p className="Color">Color: transparent</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I25} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D27}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 010010</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 92g</p>
                        <p className="Color">Color: transparent, Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I26} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D28}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 010020</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 100mm</p>
                        <p>Weight: 55g</p>
                        <p className="Color">Color: transparent, Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I27} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D29}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 010030</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 52mm</p>
                        <p>Weight: 25g</p>
                        <p className="Color">Color: transparent, Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I28} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D30}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 010040</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 62mm</p>
                        <p>Weight: 35g</p>
                        <p className="Color">Color: transparent, Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I29} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D31}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 010050</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 46g</p>
                        <p className="Color">Color: transparent, Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I30} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D32}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 010060</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 38mm</p>
                        <p>Weight: 32g</p>
                        <p className="Color">Color: transparent, Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I31} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D33}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 010070</p>
                        <p>Material: PET</p>
                        <p>Neck Size: 22mm</p>
                        <p>Weight: 13g</p>
                        <p className="Color">Color: transparent, Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I32} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D34}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 020010</p>
                        <p>Material: PP</p>
                        <p>Bottel Neck: 100mm</p>
                        <p>Weight: 27g</p>
                        <p className="Color">Color: <ul style={{width: '20px', height: '20px',backgroundColor: '#8B4513'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#ff0000'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#00ff00'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#969696'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#f0fc00'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#0019fc'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#DAA520'}}/> Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I33} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    <img src={D35}  onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}} alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 020020</p>
                        <p>Material: PP</p>
                        <p>Bottel Neck: 100mm</p>
                        <p>Weight: 25g</p>
                        <p className="Color">Color: <ul style={{width: '20px', height: '20px',backgroundColor: '#8B4513'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#ff0000'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#00ff00'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#969696'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#f0fc00'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#0019fc'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#DAA520'}}/> Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I34} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>
                    
                    <div className="info">

                        <p>Code: 020030</p>
                        <p>Material: PP</p>
                        <p>Bottel Neck: 62mm</p>
                        <p>Weight: 15g</p>
                        <p className="Color">Color: <ul style={{width: '20px', height: '20px',backgroundColor: '#8B4513'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#ff0000'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#00ff00'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#969696'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#f0fc00'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#0019fc'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#DAA520'}}/> Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I35} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    
                    
                    <div className="info">

                        <p>Code: 020040</p>
                        <p>Material: PP</p>
                        <p>Bottel Neck: 22mm</p>
                        <p>Weight: 3g</p>
                        <p className="Color">Color: <ul style={{width: '20px', height: '20px',backgroundColor: '#fff', border: '1px black solid'}}/></p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I36} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    
                    
                    <div className="info">

                        <p>Code: 020050</p>
                        <p>Material: PP</p>
                        <p>Bottel Neck: 52mm</p>
                        <p>Weight: 10g</p>
                        <p className="Color">Color: <ul style={{width: '20px', height: '20px',backgroundColor: '#8B4513'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#ff0000'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#00ff00'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#969696'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#f0fc00'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#0019fc'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#DAA520'}}/> Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I37} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    
                    
                    <div className="info">

                        <p>Code: 020060</p>
                        <p>Material: PE</p>
                        <p>Bottel Neck: 38mm</p>
                        <p>Weight: 3g</p>
                        <p className="Color">Color: <ul style={{width: '20px', height: '20px',backgroundColor: '#fff', border: '1px black solid'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#ff0000'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#0019fc'}}/> Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I38} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro" style={{height: '60%'}} className="I38"/>

                    
                    
                    <div className="info">

                        <p>Code: 020070</p>
                        <p>Material: PE</p>
                        <p>Bottel Neck: 38mm</p>
                        <p>Weight: 4g</p>
                        <p className="Color">Color: <ul style={{width: '20px', height: '20px',backgroundColor: '#fff', border: '1px black solid'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#ff0000'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#0019fc'}}/> Customized</p>
                        
                    </div>

                </div>

                <div className="Product">

                    <img src={I39} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}  alt="pro"/>

                    
                    
                    <div className="info">

                        <p>Code: 020080</p>
                        <p>Material: PP</p>
                        <p>Bottel Neck: 62mm</p>
                        <p>Weight: 10g</p>
                        <p className="Color">Color: <ul style={{width: '20px', height: '20px',backgroundColor: '#8B4513'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#ff0000'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#00ff00'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#969696'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#f0fc00'}}/>
                        <ul style={{width: '20px', height: '20px',backgroundColor: '#0019fc'}}/> <ul style={{width: '20px', height: '20px',backgroundColor: '#DAA520'}}/> Customized</p>
                        
                    </div>

                </div>


            </div>

            <section className="sec-7">

            <div className="Main7">

                <div className="M7Block1">
                    <img alt="bottomlogo" src={Logo} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    
                    <h1 className="h2" style={{color: '#fff'}}>We talk about Quality</h1>


                </div>

            </div>

            <p style={{marginTop: '50px'}}>Powered By <a href="https://devstation.pro/" target="._blank">devstation.pro</a></p>

        </section>

        </React.Fragment>
    )
}

export default Catalog